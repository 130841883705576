import { securedAxiosAPI } from 'utils/axios';
import { store } from '../../store';
// APIS

// Secured Routes

const securedRoute = {
    add: 'testRequest/add',
    base: 'testRequest',
    byPagination: 'testRequest/byPagination',
    updateCustomer: 'testRequest/update-customer',
    findSampleId: 'testRequest/findSampleId',
    // customer Portal
    customerTestReqList: 'customerPortal/customerPortalGetByPagination',
    requestDetail: 'customerPortal/customerGetTestRequestById'
};

const onlyForColumnListGeneration = {
    TEST_REQUEST_LIST: 'testRequest/generateFilterList',
    SAMPLE_SCHEDULED_LIST: 'labTestDetail/generateFilterList',
    SAMPLE_READY_FOR_QUALITY_CHECK_LIST: 'labTest/generateFilterList',
    PRODUCT_MASTER: 'lookupDetails/productDetails/generateFilterList'
};
export const getAllTestRequest = async () => {
    const res = await securedAxiosAPI.get(`${securedRoute?.base}`);
    return res.data;
};

export const getTestRequestsByPagination = async (data) => {
    const { getState } = store;
    const { page, rowsPerPage } = getState().testRequests;
    const { loggedInPortal } = getState().user;
    const { customerId } = getState().user.loggedInUser;
    let res;
    if (loggedInPortal === 'LAB') {
        res = await securedAxiosAPI.post(securedRoute?.byPagination, { page, rowsPerPage, ...data });
    } else if (loggedInPortal === 'CUSTOMER') {
        res = await securedAxiosAPI.post(securedRoute?.customerTestReqList, { page, rowsPerPage, customer_id: customerId });
    }
    return res.data;
};

export const getTestRequestData = async (id) => {
    const { getState } = store;

    const { loggedInPortal } = getState().user;
    const { customerId } = getState().user.loggedInUser;
    let res;
    if (loggedInPortal === 'LAB') {
        res = await securedAxiosAPI.get(`${securedRoute?.base}/${id}`);
    } else if (loggedInPortal === 'CUSTOMER') {
        // customer portal
        res = await securedAxiosAPI.post(securedRoute?.requestDetail, { test_request_id: id, customer_id: customerId });
    }

    return res.data[0];
};
export const addNewTest = async (data) => {
    const res = await securedAxiosAPI.post(securedRoute?.add, data);
    return res.data;
};
export const deleteById = async (data) => {
    const res = await securedAxiosAPI.delete(`${securedRoute?.base}/${data}`);
    return res.data;
};

export const testRequestFilter = async (payload) => {
    const { needColumnFilter = false, appliedFilters = false, labStatus, columnFilterName } = payload;
    const { getState, dispatch } = store;
    const { page, rowsPerPage, [columnFilterName]: columnFilter } = getState().testRequests;
    // const { loggedInPortal } = getState().user;
    const { customerId } = getState().user.loggedInUser;
    const filters = Object.keys(columnFilter);
    const data = {
        products: [],
        sampleType: [],
        sampleId: [],
        testType: [],
        batch: [],
        customers: [],
        status: [],
        tatDate: [],
        reqDate: []
    };
    filters.forEach((filterName) => {
        columnFilter[filterName].appliedList.forEach((item) => {
            if (filterName === 'tatDate' || filterName === 'reqDate') {
                data[filterName].push(item);
            } else {
                data[filterName].push(item.id);
            }
        });
    });
    let res;
    if (needColumnFilter) {
        res = await securedAxiosAPI.post(`${securedRoute?.base}/columnDynamicFilter`, {
            ...data,
            page,
            rowsPerPage,
            customerId,
            labStatus
        });
    } else {
        res = await securedAxiosAPI.post(`${securedRoute?.base}/dynamic-filter`, {
            ...payload,
            page,
            rowsPerPage,
            customer_id: customerId
        });
    }
    // if (loggedInPortal === 'LAB') {
    //     res = await securedAxiosAPI.post(securedRoute?.byPagination, { page, rowsPerPage });
    // } else if (loggedInPortal === 'CUSTOMER') {
    //     res = await securedAxiosAPI.post(securedRoute?.customerTestReqList, { page, rowsPerPage, customer_id: customerId });
    //     //
    // }

    //  const res = await securedAxiosAPI.post(`${securedRoute?.base}/dynamic-filter`, { ...data, page, rowsPerPage, customer_id: customerId });

    return res.data;
};
// Update status
export const testReqStatusUpdate = async (data) => {
    const res = await securedAxiosAPI.post(`${securedRoute?.base}/update-status`, data);
    return res.data[0];
};
// update-customer
export const updateCustomer = async (data) => {
    const res = await securedAxiosAPI.post(`${securedRoute?.updateCustomer}`, data);
    return res;
};

// fetch sample id list

export const columnListGeneration = async (urlName, data) => {
    const { getState } = store;
    const { customerId } = getState().user.loggedInUser;
    const res = await securedAxiosAPI.post(`${onlyForColumnListGeneration?.[urlName]}`, { ...data, customerId });
    return res.data;
};
