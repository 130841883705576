import { takeLatest, all, call, put } from 'redux-saga/effects';

import {
    startLookUpDetails,
    setLookUpDetailsData,
    startAddEditNewLookupsDetails,
    setLookUpMasterData,
    startLookUpDetailsByLookupId,
    completedFetch,
    startLookUpMaster,
    startLookUpDetailsByLookupId1,
    startLookUpDetailsByLookupId2,
    startLookUpDetailsByLookupId3,
    startLookUpDetailsByLookupId4,
    startLookUpDetailsByLookupId5,
    setLookUpDetails1Data,
    setLookUpDetails2Data,
    setLookUpDetails3Data,
    setLookUpDetails4Data,
    setLookUpDetails5Data,
    startUpdateingStatusById,
    startUpdateData,
    // Roles Permission
    //
    startProductsColumnList,
    setProductsColumnList,
    //
    addUpdatePermissions
} from './lookupDetailsSlice.selector';
import { setFormNavigate } from '../formNavigate/formNavigateSlice';
/// API's

import {
    getLookUpDetailsData,
    GetLookUpDetailsDataByLookupIdAndPagination,
    addLookUpDetails,
    getLookupMasterData,
    GetLookUpDetailsDataByLookupId,
    updateStatusById,
    updateLookupData,
    productsListByColumn
} from '../../../utils/apis/lookup';

import { addRolesAccess } from '../../../utils/apis/role-access';

import { openSnackbar } from '../snackbar';

// (1) Response Handler
function* setLookupDetails({ payload }) {
    try {
        let data;
        if (payload?.lookup_id) {
            data = yield call(GetLookUpDetailsDataByLookupIdAndPagination, payload);
        } else {
            data = yield call(getLookUpDetailsData, payload);
        }
        yield put(setLookUpDetailsData(data));
        yield put(completedFetch());
    } catch (error) {
        yield put(
            openSnackbar({
                open: true,
                message: error.message,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                close: false,
                actionButton: false
            })
        );
        yield put(completedFetch());
    }
}
// (1) Watcher for Full Details
function* startFullLookUp() {
    yield takeLatest(startLookUpDetails.type, setLookupDetails);
}
// (2) Response Handler
function* setupAddDetails({ payload }) {
    try {
        const { configPage } = payload.data;
        yield call(addLookUpDetails, payload.data);
        if (configPage === 'ProductMaster') {
            //
            yield put(
                startProductsColumnList({
                    ...payload.productsTableData
                })
            );
        } else {
            yield put(startLookUpDetails(payload.tableData));
        }

        yield put(
            openSnackbar({
                open: true,
                message: 'Updated Successfully ',
                variant: 'alert',
                alert: {
                    color: 'success'
                },
                close: false,
                actionButton: false
            })
        );

        yield put(completedFetch());
    } catch (error) {
        yield put(
            openSnackbar({
                open: true,
                message: error.message,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                close: false,
                actionButton: false
            })
        );
        yield put(completedFetch());
    }
}
// (2) Watcher
function* startAddDetails() {
    yield takeLatest(startAddEditNewLookupsDetails.type, setupAddDetails);
}
// (3) Response Handler
function* setLookupMaster() {
    try {
        const data = yield call(getLookupMasterData);
        yield put(setLookUpMasterData(data));
        yield put(completedFetch());
    } catch (error) {
        yield put(
            openSnackbar({
                open: true,
                message: error.message,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                close: false,
                actionButton: false
            })
        );
        yield put(completedFetch());
    }
}
// (3)
function* startFullLookUpMaster() {
    yield takeLatest(startLookUpMaster.type, setLookupMaster);
}

// (4) Response Handler
function* setDataByLookupId({ payload }) {
    try {
        const data = yield call(GetLookUpDetailsDataByLookupId, payload);
        yield put(setLookUpDetailsData(data));
        yield put(completedFetch());
    } catch (error) {
        yield put(
            openSnackbar({
                open: true,
                message: error.message,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                close: false,
                actionButton: false
            })
        );
        yield put(completedFetch());
    }
}
// (4) Watcher
function* startLookUpDataByLookupId() {
    yield takeLatest(startLookUpDetailsByLookupId.type, setDataByLookupId);
}
// (4.1) Response Handler
function* setDataByLookupId1({ payload }) {
    try {
        const data1 = yield call(GetLookUpDetailsDataByLookupId, payload);
        yield put(setLookUpDetails1Data(data1));
        yield put(completedFetch());
    } catch (error) {
        yield put(
            openSnackbar({
                open: true,
                message: error.message,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                close: false,
                actionButton: false
            })
        );
        yield put(completedFetch());
    }
}
// (4.1) Watcher
function* startLookUpDataByLookupId1() {
    yield takeLatest(startLookUpDetailsByLookupId1.type, setDataByLookupId1);
}
// (4.2) Response Handler
function* setDataByLookupId2({ payload }) {
    try {
        const data = yield call(GetLookUpDetailsDataByLookupId, payload);
        yield put(setLookUpDetails2Data(data));
        yield put(completedFetch());
    } catch (error) {
        yield put(
            openSnackbar({
                open: true,
                message: error.message,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                close: false,
                actionButton: false
            })
        );
        yield put(completedFetch());
    }
}
// (4.2) Watcher
function* startLookUpDataByLookupId2() {
    yield takeLatest(startLookUpDetailsByLookupId2.type, setDataByLookupId2);
}
// (4.3) Response Handler
function* setDataByLookupId3({ payload }) {
    try {
        const data = yield call(GetLookUpDetailsDataByLookupId, payload);
        yield put(setLookUpDetails3Data(data));
        yield put(completedFetch());
    } catch (error) {
        yield put(
            openSnackbar({
                open: true,
                message: error.message,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                close: false,
                actionButton: false
            })
        );
        yield put(completedFetch());
    }
}
// (4.3) Watcher
function* startLookUpDataByLookupId3() {
    yield takeLatest(startLookUpDetailsByLookupId3.type, setDataByLookupId3);
}
// (4.4) Response Handler
function* setDataByLookupId4({ payload }) {
    try {
        const data = yield call(GetLookUpDetailsDataByLookupId, payload);
        yield put(setLookUpDetails4Data(data));
        yield put(completedFetch());
    } catch (error) {
        yield put(
            openSnackbar({
                open: true,
                message: error.message,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                close: false,
                actionButton: false
            })
        );
        yield put(completedFetch());
    }
}
// (4.4) Watcher
function* startLookUpDataByLookupId4() {
    yield takeLatest(startLookUpDetailsByLookupId4.type, setDataByLookupId4);
}
// (4.5) Response Handler
function* setDataByLookupId5({ payload }) {
    try {
        const data = yield call(GetLookUpDetailsDataByLookupId, payload);
        yield put(setLookUpDetails5Data(data));
        yield put(completedFetch());
    } catch (error) {
        yield put(
            openSnackbar({
                open: true,
                message: error.message,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                close: false,
                actionButton: false
            })
        );
        yield put(completedFetch());
    }
}
// (4.5) Watcher
function* startLookUpDataByLookupId5() {
    yield takeLatest(startLookUpDetailsByLookupId5.type, setDataByLookupId5);
}
// (5) Response Handler
function* setUpForStatusUpdate({ payload }) {
    try {
        const { configPage } = payload;

        yield call(updateStatusById, payload.data);
        if (configPage === 'ProductMaster') {
            //
            yield put(
                startProductsColumnList({
                    ...payload.productsTableData
                })
            );
        } else {
            yield put(startLookUpDetails(payload.tableData));
        }

        yield put(
            openSnackbar({
                open: true,
                message: 'Updated Successfully ',
                variant: 'alert',
                alert: {
                    color: 'success'
                },
                close: false,
                actionButton: false
            })
        );
        yield put(completedFetch());
    } catch (error) {
        yield put(
            openSnackbar({
                open: true,
                message: error.message,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                close: false,
                actionButton: false
            })
        );
        yield put(completedFetch());
    }
}
// (5) Watcher
function* startUpdateStatus() {
    yield takeLatest(startUpdateingStatusById.type, setUpForStatusUpdate);
}

// (6) Response Handler
function* setUpdateLookupData({ payload }) {
    try {
        const { configPage } = payload;
        yield call(updateLookupData, payload.data);
        if (configPage === 'ProductMaster') {
            //
            yield put(
                startProductsColumnList({
                    ...payload.productsTableData
                })
            );
        } else {
            yield put(startLookUpDetails(payload.tableData));
        }

        yield put(
            openSnackbar({
                open: true,
                message: 'Updated Successfully ',
                variant: 'alert',
                alert: {
                    color: 'success'
                },
                close: false,
                actionButton: false
            })
        );
        yield put(completedFetch());
    } catch (error) {
        yield put(
            openSnackbar({
                open: true,
                message: error.message,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                close: false,
                actionButton: false
            })
        );
        yield put(completedFetch());
    }
}
// (6) Watcher
function* startUpdateLookupData() {
    yield takeLatest(startUpdateData.type, setUpdateLookupData);
}
// Roles Access Permission

// Roles Access Permission Response (1)
function* addingResponseHandler({ payload }) {
    try {
        yield call(addRolesAccess, payload);
        yield put(setFormNavigate(true));
        yield put(completedFetch());
        yield put(
            openSnackbar({
                open: true,
                message: 'Updated Successfully',
                variant: 'alert',
                alert: {
                    color: 'success'
                },
                close: false,
                actionButton: false
            })
        );
    } catch (error) {
        yield put(
            openSnackbar({
                open: true,
                message: error.message,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                close: false,
                actionButton: false
            })
        );
        yield put(completedFetch());
    }
}
//  Roles Access Permission watcher(1)
function* startAddingPermissionFetchWatcher() {
    yield takeLatest(addUpdatePermissions.type, addingResponseHandler);
}

// Products

// After ValidationList Response (3)
function* productsColumnListResponseHandler({ payload }) {
    try {
        let response = [];
        // eslint-disable-next-line eqeqeq
        response = yield call(productsListByColumn, payload);
        yield put(setProductsColumnList(response));
        yield put(completedFetch());
    } catch (error) {
        yield put(
            openSnackbar({
                open: true,
                message: error.message,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                close: false,
                actionButton: false
            })
        );
        yield put(completedFetch());
    }
}
// Scheduled List with Test Type Watcher  (3)
function* startLabTestScheduledListWatcher() {
    yield takeLatest(startProductsColumnList.type, productsColumnListResponseHandler);
}

// Accumulator
export default function* lookupDetailsDataSaga() {
    yield all([
        call(startFullLookUp),
        call(startAddDetails),
        call(startFullLookUpMaster),
        call(startLookUpDataByLookupId),
        call(startUpdateStatus),
        call(startLookUpDataByLookupId1),
        call(startLookUpDataByLookupId2),
        call(startLookUpDataByLookupId3),
        call(startLookUpDataByLookupId4),
        call(startLookUpDataByLookupId5),
        call(startUpdateLookupData),
        // Products
        call(startLabTestScheduledListWatcher),
        // Roles Access Permission
        call(startAddingPermissionFetchWatcher)
    ]);
}
