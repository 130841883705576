import { securedAxiosAPI } from 'utils/axios';
import { store } from '../../store';
// APIS

// Secured Routes

const securedRoute = {
    labDetailsByLabTestId: '/labTestDetail/labTests',
    resultEntry: '/labTestDetail/result',
    afterValidationLabTestList: '/labTestDetail/get-all-by-pagination-status',
    afterValidationLabTestListGroupBYAnalysisType: '/labTestDetail/get-all-by-pagination-status-group-by-analysis',
    testing: '/labTestDetail/labTestId/17',
    dynamicFilter: '/labTestDetail/dynamic-filter',
    getSingleTestResultResultByLabTestIdAndTestRequested: '/labTestDetail/getAverageResultByLabTestIdAndTestRequested',
    getAnalysisGroupedTestByLabTestIdandTestType: '/labTestDetail/getAverageByLabTestIdandTestType',
    scheduledTestByAnalysisType: 'labTestDetail/findScheduledTestByAnalysisType',
    // grouped by Analysis type
    resultEntryBatch: '/labTestDetail/result-entery-batch',
    customerGetAverageByLabTestIdandTestType: 'customerPortal/customerGetAverageByLabTestIdandTestType',
    // column dynamic filter
    columnDynamicFilter: '/labTestDetail/columnDynamicFilter'
};

// Axios call

export const labTestByLabTestIdAndReqTest = async (data) => {
    const res = await securedAxiosAPI.post(securedRoute?.labDetailsByLabTestId, data);
    return res.data;
};
export const addlabDetailsResultEnter = async (data) => {
    const res = await securedAxiosAPI.post(`${securedRoute?.resultEntry}`, data);
    return res.data;
};
// /result-entry list page
// /certificate-generation certificate page
export const afterValidationLabListByStatus = async (data) => {
    const { getState } = store;
    const { groupByAnalysis, needColumnFilter = false, labStatus, pageType, paginationsPageName } = data;
    //   // validatedListPage // certificatePage

    const { columnFilter, columnFilterTwo, paginations } = getState().lab;
    const { page, rowsPerPage } = paginations[paginationsPageName];
    const currentColumnFilter = labStatus === 1 ? columnFilter : columnFilterTwo;
    const filters = Object.keys(currentColumnFilter);
    const columnData = {
        products: [],
        sampleType: [],
        sampleId: [],
        testType: [],
        batch: [],
        customers: [],
        status: [],
        requestedTest: [],
        testIterationCount: [],
        analysisTestCount: [],
        authorisation: []
    };
    filters.forEach((filterName) => {
        currentColumnFilter[filterName].appliedList.forEach((item) => {
            if (filterName === 'tatDate' || filterName === 'reqDate') {
                columnData[filterName].push(item);
            } else {
                columnData[filterName].push(item.id);
            }
        });
    });
    const res = await securedAxiosAPI.post(
        // eslint-disable-next-line no-nested-ternary
        needColumnFilter
            ? securedRoute?.columnDynamicFilter // column filter
            : groupByAnalysis // radio grouping
            ? securedRoute?.afterValidationLabTestListGroupBYAnalysisType
            : securedRoute?.afterValidationLabTestList,
        { ...data, page, rowsPerPage, ...columnData, pageType } //  rowsPerPage: appliedFilters ? 10 : page: appliedFilters ? 0 :
    );
    return res.data;
};

/// Single Test Result by Lab test Id and Requested Test Id
export const getSingleTestResultByLabTestIdAndReqTestId = async (data) => {
    const { getState } = store;

    const { loggedInPortal } = getState().user;
    const { customerId } = getState().user.loggedInUser;
    let res;
    if (loggedInPortal === 'LAB') {
        res = await securedAxiosAPI.post(securedRoute?.getSingleTestResultResultByLabTestIdAndTestRequested, data);
    } else if (loggedInPortal === 'CUSTOMER') {
        res = await securedAxiosAPI.post(securedRoute?.customerGetAverageByLabTestIdandTestType, { ...data, customer_id: customerId });
    }

    return res.data;
};
//  Analysis Grouped Test Result Lab Test Id and Test Type
export const getGroupedTestResultByLabTestIdAndTestType = async (data) => {
    const res = await securedAxiosAPI.post(`${securedRoute?.getAnalysisGroupedTestByLabTestIdandTestType}`, data);
    return res.data;
};
// scheduled Test By Analysis Type
export const scheduledTestByAnalysisType = async (data) => {
    const res = await securedAxiosAPI.post(securedRoute?.scheduledTestByAnalysisType, data);
    return res.data;
};
//
export const enterResultEntryBatch = async (data) => {
    const res = await securedAxiosAPI.post(securedRoute?.resultEntryBatch, data);
    return res.data;
};
