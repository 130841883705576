import { lazy } from 'react';

// project imports

import Loadable from 'ui-component/Loadable';

const Intransit = Loadable(lazy(() => import('views/pages/manage-lab-test/InTransit')));
const ViewCertificate = Loadable(lazy(() => import('views/pages/manage-test-requests/view-certificate')));
const EditTestTemplate = Loadable(lazy(() => import('views/pages/manage-test-requests/test-templates/edit-template')));
const ViewTemplateDetails = Loadable(lazy(() => import('views/pages/manage-test-requests/test-templates/view-details')));
const RestUserPassword = Loadable(lazy(() => import('views/pages/admin-users/reset-password')));
const EditUserData = Loadable(lazy(() => import('views/pages/admin-users/edit')));
const EditCustomerUser = Loadable(lazy(() => import('views/pages/manage-customer-users/edit-user')));
const RolesAccess = Loadable(lazy(() => import('views/pages/configuration/role-access')));
const AdminConfiguration = Loadable(lazy(() => import('views/pages/configuration')));
const ProductManagement = Loadable(lazy(() => import('views/pages/configuration/products-management')));

const ViewUsersrDetails = Loadable(lazy(() => import('views/pages/admin-users/view-user')));
const GeneratedSampleId = Loadable(lazy(() => import('views/pages/manage-test-requests/generate-sample-id')));
const ResultEntryGrouped = Loadable(lazy(() => import('views/pages/manage-lab-test/scheduled-test/Result-Entry-grouped')));
const ResultViewPrintGroup = Loadable(lazy(() => import('views/pages/manage-lab-test/ResultGeneration/ResultViewPrintGroup')));
const CustomerUserDetails = Loadable(lazy(() => import('views/pages/manage-customer-users/view-user-details')));
const ReportsDashboard = Loadable(lazy(() => import('views/pages/reports')));
const SampleLogReport = Loadable(lazy(() => import('views/pages/reports/sampleLog')));
const ManageLabTest = Loadable(lazy(() => import('views/pages/manage-lab-test')));
const ScheduledList = Loadable(lazy(() => import('views/pages/manage-lab-test/scheduled-test')));
const SampleSplitting = Loadable(lazy(() => import('views/pages/manage-lab-test/SampleSplitting')));
const LabTestUnvalidList = Loadable(lazy(() => import('views/pages/manage-lab-test/LabTestUnvalidList')));
const ResultEntry = Loadable(lazy(() => import('views/pages/manage-lab-test/scheduled-test/Result-Entry')));
const Archival = Loadable(lazy(() => import('views/pages/manage-lab-test/Archival')));
const Disposal = Loadable(lazy(() => import('views/pages/manage-lab-test/Disposal')));
const UnfitSample = Loadable(lazy(() => import('views/pages/manage-lab-test/Unfit')));
const NonConfermance = Loadable(lazy(() => import('views/pages/manage-lab-test/scheduled-test/Non-Conformance')));
const ResultGenerationList = Loadable(lazy(() => import('views/pages/manage-lab-test/ResultGeneration')));
const ResultViewPrint = Loadable(lazy(() => import('views/pages/manage-lab-test/ResultGeneration/ResultViewPrintSingle')));
const EditTestSample = Loadable(lazy(() => import('views/pages/manage-test-requests/edit-test-request')));
const ViewTestRequest = Loadable(lazy(() => import('views/pages/manage-test-requests/view-test-request')));
const LookupMaster = Loadable(lazy(() => import('views/pages/configuration/lookup-master')));
const CustomerMangement = Loadable(lazy(() => import('views/pages/manage-customer')));
const CustomerUsersMangement = Loadable(lazy(() => import('views/pages/manage-customer-users')));
const AddClientUser = Loadable(lazy(() => import('views/pages/manage-customer-users/add-customer-user')));
const AddNewCustomer = Loadable(lazy(() => import('views/pages/manage-customer/add-new-customer')));
const EditClient = Loadable(lazy(() => import('views/pages/manage-customer/edit-customer')));
const ViewClientDetails = Loadable(lazy(() => import('views/pages/manage-customer/view-customer')));
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));
const Users = Loadable(lazy(() => import('views/pages/admin-users')));
const TestRequests = Loadable(lazy(() => import('views/pages/manage-test-requests/test-requests-list')));
const ManageTestRequest = Loadable(lazy(() => import('views/pages/manage-test-requests')));
const AddUser = Loadable(lazy(() => import('views/pages/admin-users/add-new-user')));
const TestTemplateList = Loadable(lazy(() => import('views/pages/manage-test-requests/test-templates/list-view')));
const AddTestTemplate = Loadable(lazy(() => import('views/pages/manage-test-requests/test-templates/add-template')));
// Pages
const NewTestSample = Loadable(lazy(() => import('views/pages/manage-test-requests/new-test-sample')));

// ==============================|| MAIN ROUTING ||============================== //

const MapRoutes = [
    {
        path: '/',
        element: DashboardDefault,
        screenKeyName: 'DASHBOARD'
    },
    // Configuration
    {
        path: '/configuration',
        element: AdminConfiguration,
        screenKeyName: 'CONFIG_DASH'
    },
    {
        path: '/configuration/products-management',
        element: ProductManagement,
        screenKeyName: 'PRODUCT_MANAGEMENT'
    },
    // ProductManagement
    {
        path: '/configuration/master-lookups',
        element: LookupMaster,
        screenKeyName: 'LOOKUP_LIST'
    },
    {
        path: '/configuration/role-permissions',
        element: RolesAccess,
        screenKeyName: 'ROLE_ACCESS'
    },
    // Customer Management
    {
        path: '/configuration/customer-management',
        element: CustomerMangement,
        screenKeyName: 'CUSTOMER_LIST'
    },
    {
        path: '/configuration/customer-management/add-customer',
        element: AddNewCustomer,
        screenKeyName: 'ADD_CUSTOMER'
    },
    {
        path: '/configuration/customer-management/:id/edit',
        element: EditClient,
        screenKeyName: 'EDIT_CUSTOMER'
    },
    {
        path: '/configuration/customer-management/:id/view',
        element: ViewClientDetails,
        screenKeyName: 'VIEW_CUSTOMER'
    },
    {
        path: '/configuration/customer-management/:id/user-management',
        element: CustomerUsersMangement,
        screenKeyName: 'CUSTOMER_USERS_LIST'
    },
    {
        path: '/configuration/customer-management/:id/user-management/:user_id/user_details',
        element: CustomerUserDetails,
        screenKeyName: 'CUSTOMER_USERS_LIST'
    },
    {
        path: '/configuration/customer-management/:id/user-management/:user_id/edit_details',
        element: EditCustomerUser,
        screenKeyName: 'ADD_CUSTOMER_USER'
    },
    {
        path: '/configuration/customer-management/:id/user-management/add-user',
        element: AddClientUser,
        screenKeyName: 'ADD_CUSTOMER_USER'
    },
    // Lab Users
    {
        path: '/configuration/users',
        element: Users,
        screenKeyName: 'LAB_USERS_LIST'
    },
    {
        path: '/configuration/users/:id/view',
        element: ViewUsersrDetails,
        screenKeyName: 'VIEW_USER'
    },
    {
        path: '/configuration/users/:id/edit',
        element: EditUserData,
        screenKeyName: 'EDIT_USER'
    },
    {
        path: '/configuration/users/:id/reset-password',
        element: RestUserPassword,
        screenKeyName: 'ADMIN_PASSWORD_MANAGEMENT'
    },
    {
        path: '/configuration/users',
        element: Users,
        screenKeyName: 'LAB_USERS_LIST'
    },
    { path: '/configuration/users/add-user', element: AddUser, screenKeyName: 'ADD_LAB_USER' },
    // Reports
    {
        path: '/report',
        element: ReportsDashboard,
        screenKeyName: 'REPORT_DASH'
    },
    {
        path: '/report/sample-log',
        element: SampleLogReport,
        screenKeyName: 'SAMPLE_LOG'
    },

    // Test Request
    { path: '/sample-reception', element: ManageTestRequest, screenKeyName: 'TEST_REQUEST_DASH' },
    { path: '/sample-reception/test-requests', element: TestRequests, screenKeyName: 'TEST_REQUEST_LIST' },
    { path: '/sample-reception/adhoc-generate-sample-id', element: GeneratedSampleId, screenKeyName: 'GENERATE_SAMPLE_ID' },
    // Test Request Template
    { path: '/sample-reception/test-templates', element: TestTemplateList, screenKeyName: 'TEST_TEMPLATE_LIST' }, // update the screenkey
    { path: '/sample-reception/test-templates/add-template', element: AddTestTemplate, screenKeyName: 'TEST_TEMPLATE_ADD' },
    { path: '/sample-reception/test-templates/:id/view-template', element: ViewTemplateDetails, screenKeyName: 'TEST_TEMPLATE_VIEW' },
    { path: '/sample-reception/test-templates/:id/edit-template', element: EditTestTemplate, screenKeyName: 'TEST_TEMPLATE_EDIT' },

    { path: '/sample-reception/test-requests/add-request', element: NewTestSample, screenKeyName: 'NEW_TEST_REQUEST' },
    { path: '/sample-reception/test-requests/:test_request_id/edit', element: EditTestSample, screenKeyName: 'EDIT_TEST_REQUEST' },
    {
        path: '/sample-reception/test-requests/:test_request_id/request_details',
        element: ViewTestRequest,
        screenKeyName: 'VIEW_TEST_REQUEST'
    },
    {
        path: '/sample-reception/test-requests/:test_request_id/request_details/:requested_test_id/certificate',
        element: ResultViewPrint,
        screenKeyName: 'VIEW_TEST_REQUEST'
    },
    {
        path: '/sample-reception/test-requests/:test_request_id/request_details/:lab_test_id/:test_type/certificate',
        element: ViewCertificate,
        screenKeyName: 'VIEW_TEST_REQUEST'
    },
    // Lab Management
    { path: '/manage-lab-test', element: ManageLabTest, screenKeyName: 'LAB_DASH' },
    { path: '/manage-lab-test/in-transit', element: Intransit, screenKeyName: 'SPACIMEN_IN_TRANSIT' },
    { path: '/manage-lab-test/specimen-list', element: LabTestUnvalidList, screenKeyName: 'SAMPLE_READY_FOR_QUALITY_CHECK_LIST' },
    { path: '/manage-lab-test/result-entry', element: ScheduledList, screenKeyName: 'SAMPLE_SCHEDULED_LIST' },
    {
        path: '/manage-lab-test/result-entry/:test_request_id/:requested_test_id/:lab_test_id/result-entry',
        element: ResultEntry,
        screenKeyName: 'TEST_REQUEST_ENTER_RESULT'
    },
    {
        path: '/manage-lab-test/result-entry/:test_request_id/:test_type/grouped-result-entry',
        element: ResultEntryGrouped,
        screenKeyName: 'TEST_REQUEST_ENTER_RESULT'
    },
    { path: '/manage-lab-test/certificate-generation', element: ResultGenerationList, screenKeyName: 'GENERATE_RESULT' },

    {
        path: '/manage-lab-test/certificate-generation/:test_request_id/:requested_test_id/:lab_test_id/view',
        element: ResultViewPrint,
        screenKeyName: 'VIEW_RESULT_PRINT'
    },
    {
        path: '/manage-lab-test/certificate-generation/:test_request_id/:test_type/:lab_test_id/view-all',
        element: ResultViewPrintGroup,
        screenKeyName: 'VIEW_RESULT_PRINT'
    },

    {
        path: '/manage-lab-test/certificate-generation/:test_request_id/:requested_test_id/:lab_test_id/edit',
        element: ResultEntry,
        screenKeyName: 'EDIT_RESULT'
    },
    { path: '/manage-lab-test/:test_request_id/splitting', element: SampleSplitting, screenKeyName: 'SAMPLE_SPLITTING' },

    { path: '/manage-lab-test/:test_request_id/non-conformance', element: NonConfermance, screenKeyName: 'NON_CONFERMANCE' },
    {
        path: '/manage-lab-test/specimen-archive',
        element: Archival,
        screenKeyName: 'ARCHIVAL'
    },
    {
        path: '/manage-lab-test/specimen-disposal',
        element: Disposal,
        screenKeyName: 'DISPOSAL'
    },
    {
        path: '/manage-lab-test/unfit-samples',
        element: UnfitSample,
        screenKeyName: 'UNFIT_SAMPLE'
    },
    {
        path: '/manage-lab-test/unfit-samples/:test_request_id/edit',
        element: NonConfermance,
        screenKeyName: 'NON_CONFERMANCE_EDIT'
    }
];

export default MapRoutes;
